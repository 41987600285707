cpt.generic = {
    init: function(){
        $('.scrollup').click(function(e){
            e.preventDefault();
            $("html, body").animate({ scrollTop: 0 }, 600);
            return false;
        });

        cpt.generic.setBodyPanelHt();

        $(window).scroll(function(){
            if ($(this).scrollTop() > 100) {
                $('.scrollup').fadeIn();
            } else {
                $('.scrollup').fadeOut();
            }
        });

        $('.btnEnquiry').click(function(e){
            e.preventDefault();
            var subj = $(this).attr('subj');
            var url = '/index.php?module=webBasic_contactUs&_spAction=new&tpl=1&showHTML=0&s=' + subj;
            Util.openModalForLink.call(this,{
                url: url,
                title: 'Send Enquiry',
                'width': '600'
            });
        });

        $('.imgAsBg').each(function () {
            var pic = $(this).attr('pic');
            var bgPosition = $(this).attr('bgPosition');

            $(this).css({
                'background-image': 'url("' + pic + '")'
            });

            if (bgPosition != '') {
                $(this).css({
                    'background-position': bgPosition
                });
            }
        });
    },
    isLoggedIn: function(returnStatus){
        $.ajax({
            type: 'GET',
            url: '/index.php?_spAction=isLoggedInWWW&showHTML=0',
            dataType: 'json',
            success: function(json) {
                res = json.status;

                if (returnStatus){
                    return res;
                } else {
                    if (json.status == 0){
                        Util.alert('Please login as a member before you proceed!');
                        Util.hideProgressInd();
                    }
                }
            },
            data: {},
            async: false
        });

        return res;
    },

    setBodyPanelHt: function(){
        var bodyHt = $(window).innerHeight() - $('.header').height() - $('.topBanner').height() - $('.navbar').height() - $('.footerTop').height() - 25;
        $('.bodyPanel').css('min-height', bodyHt + 'px' );
    }
}


const $navbar = $('#mainNav');

$('a.slide[href^="#"]').on('click', function(e) {
    e.preventDefault();
    const scrollTop = $($(this).attr('href')).position().top - $navbar.outerHeight();
    $('html, body').animate({ scrollTop }, 'slow');
})

$(function () {
    $('.sCarousel').slick({
        slidesToShow: 4,
        slidesToScroll: 4,
        dots: true,
        speed: 300,
        autoplay: true,
        easing: 'swing',
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    })
    .on("afterChange", function (e, slick) {
        $('.sCarousel').slick("setOption", "autoplaySpeed", 5000);
    });
});

