Util.createCPObject('cpm.catalog.quote');

cpm.catalog.quote.init = function(){
    $('#btnModifyQuote').on('click', function(e){
        cpm.catalog.quote.btnModifyQuote.call(this, e);
    });

    $('#printQuote').on('click', function(e){
        cpm.catalog.quote.printToPdf.call(this,e);
    });
}

cpm.catalog.quote.btnModifyQuote = function(e){
    e.preventDefault();
    var rowID = $(this).attr('quoteId');
    var msg = "Are you sure you want to place this quote to the \"wishlist\" for further modificiation?";

    Util.confirm(msg, function() {
        var url = "/index.php?module=catalog_quote&_spAction=placePreviousQuoteToWishlist&showHTML=0";
        var data = {
            record_id: rowID
        };

        $.post(url, data, function(json) {
            if (json.status == 'success') {
                document.location = json.returnUrl;
            } else {
                Util.alert(json.message);
            }
        }, 'json')

    },
        {
            title: 'Modify Quote Request'
        }
    );
}

cpm.catalog.quote.printToPdf = function(e){
    e.preventDefault();
    var record_id = $('#qidEnc').val();

    var url = "/print-quote/" + record_id + '/';
    window.open(url, '_blank');
}
