Util.createCPObject('cpm.catalog.product');

Util.createCPObject('cpm.catalog.product');

cpm.catalog.product = {
    init: function(){
        $('#searchTop select#fld_category_id').on('change', function(){
            Util.loadDropdownByJSON('category_id', $(this).val(), 'fld_sub_category_id', 'webBasic_subCategory', 'searchTop');
        });

        $('.btnAddToWishlist').livequery('click', function(e){ //from product pages
            cpm.catalog.product.addToWishlist.call(this, e);
        });
    },

    addToWishlist: function(e){
        e.preventDefault();
        var btnsOuter = $(this).closest('.btnsOuter');

        Util.showProgressInd();
        var itemId = $(this).attr('itemId');
        var url = '/index.php?module=catalog_wishlist&_spAction=addToCart';
        $.getJSON(url, {itemId: itemId}, function(json){
            Util.hideProgressInd();
            var html = "<a href='" + json.wishlistUrl + "' class='btn btn-success btnViewBasket'>Added to Wishlist <i class='fas fa-check'></i></a>";
            btnsOuter.html(html);
        });
    },
}