Util.createCPObject('cpm.catalog.wishlist');

cpm.catalog.wishlist = {
    init: function(){
    	$('.remove-item').click(function(e){
            cpm.catalog.wishlist.removeFromCart.call(this, e);
        })
    	$('#btnRequestQuote').click(function(e){
    		cpm.catalog.wishlist.requestForQuote.call(this, e);
    	})

        $("tr input[name=qty]").livequery('change', function(e){
            cpm.catalog.wishlist.updateQuantity.call(this, e);
        });
    },

    removeFromCart: function(e){
        e.preventDefault();
        Util.showProgressInd();
        var wishlistId = $(this).attr('wishlistId');
        var url = '/index.php?module=catalog_wishlist&_spAction=removeFromCart';
        $.get(url, {wishlistId: wishlistId}, function(json){
            document.location = document.location;
        });
    },

    updateQuantity: function(e){
        e.preventDefault();
        var rowObj = $(this).closest('tr');
        var unitPrice = $('.unitPrice', rowObj).text();
        var unitPrice = unitPrice.replace(/,/g, '');
        var wishlist_id = $(this).data('wishlist_id');

        var qty = $(this).val();
        var url = '/index.php?module=catalog_wishlist&_spAction=addToCart&showHTML=0';

        // Util.showProgressInd();
        $.getJSON(url, {wishlist_id: wishlist_id, qty: qty}, function(json){
            if(qty == 0){
                location.reload();
            }
            Util.hideProgressInd();
        });

        // update the total text
        $('.subTotal', rowObj).text(Util.addCommasToNumber(unitPrice * qty));

        netTotal = 0;

        $(".wishlist td.subTotal").each(function() {
            var value = $(this).text().replace(/,/g, '');
            // add only if the value is number
            if(!isNaN(value) && value.length != 0) {
                netTotal += parseFloat(value);
            }
        });

        $('.netTotal').text(Util.addCommasToNumber(netTotal));
    },

    requestForQuote: function(e){
        e.preventDefault();
        Util.showProgressInd();
        if (cpt.generic.isLoggedIn(true)){
        } else {
            Util.hideProgressInd();
            $('#loginRegisterWrapper').slideDown();
            $('html,body').animate({scrollTop: $("#loginRegisterWrapper").offset().top - 50},'slow');
        }
    }
}
